import React from "react";
import { styled } from "styled-components";
import { COLORS } from "../../constants";

const StyledButton = styled.button`
    border-radius: 2em;
    border-color: rgba(0, 0, 0, 0);
    width: auto;
    color: rgb(255, 255, 255);
    font-family: inherit;
    font-weight: bold;
    font-size: 16px;
    border-width: 0px;
    padding: 0.2em;
    margin: 0.2em;
    max-width: 100%;
    height: 2.5em;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 1px 0px 0px;
    cursor: pointer;
`;

const ButtonContent = styled.span`
    padding: 12px;
    background-color: rgba(0, 0, 0, 0);
`;

type ButtonProps = {
    onClick: () => void;
    label: string;
    backgroundColor?: string;
};

export const Button: React.FC<ButtonProps> = ({
    onClick,
    label,
    backgroundColor = COLORS.main,
}) => {
    return (
        <StyledButton onClick={onClick} style={{ backgroundColor }}>
            <ButtonContent>{label}</ButtonContent>
        </StyledButton>
    );
};

type WhatsAppButtonProps = {
    link: string;
    label: string;
};

export const WhatsAppButton: React.FC<WhatsAppButtonProps> = (props) => {
    return <ExternalButton backgroundColor={COLORS.whatsapp} {...props} />;
};

type ExternalButtonProps = {
    link: string;
    label: string;
    backgroundColor: string;
};

export const ExternalButton: React.FC<ExternalButtonProps> = ({
    link,
    label,
    backgroundColor,
}) => {
    return (
        <Button
            backgroundColor={backgroundColor}
            onClick={() => window.open(link)}
            label={label}
        />
    );
};
