export const Kalender = () => {
    const width = Math.min(16 * 60, (window.innerWidth * 90) / 100);
    const height = Math.max(400, (width * 3) / 4);

    const isSmallScreen = width < 600;
    const mode = isSmallScreen ? "AGENDA" : "MONTH";
    const showDate = isSmallScreen ? "0" : "1";
    const showNav = isSmallScreen ? "0" : "1";
    const showTabs = isSmallScreen ? "0" : "1";

    return (
        <iframe
            src={`https://calendar.google.com/calendar/embed?height=${height}&wkst=2&ctz=Europe%2FVienna&bgcolor=%23fff5ee&mode=${mode}&showPrint=0&showCalendars=0&showTz=0&showTitle=0&showDate=${showDate}&showNav=${showNav}&showTabs=${showTabs}&src=bHVkb3BpYUBic2NobWlkaHViZXIuYXQ&color=%23AD1457`}
            style={{ border: 0 }}
            width={width}
            height={height}
            scrolling="no"
            title="calendar"
        ></iframe>
    );
};
