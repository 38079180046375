import React from "react";

export const Spielregeln = () => {
    return (
        <section>
            <h2>Spielregeln</h2>
            <p>
                Um eine respektvolle und freundliche Atmosphäre zu schaffen,
                bitten wir alle Mitglieder, folgende Grundregeln zu beachten:
            </p>
            <ul>
                <li>Respektiere die Meinungen und Vorlieben anderer.</li>
                <li>Keine unangemessenen Kommentare oder Verhaltensweisen.</li>
                <li>
                    Sei hilfsbereit und freundlich, besonders zu neuen
                    Mitgliedern.
                </li>
            </ul>
        </section>
    );
};
