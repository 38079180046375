import React, { Dispatch, SetStateAction } from "react";
import styled from "styled-components";

const StyledHeader = styled.header`
    background-color: #ad1457;
    color: white;
    padding: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 2em;
`;

const RotatingImage = styled.img`
    animation: App-logo-spin infinite 30s linear;
    max-height: 100px;
    margin: 1em;
    cursor: pointer;

    @keyframes App-logo-spin {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(360deg);
        }
    }
`;

const TextHeader = styled.div`
    h1 {
        font-size: 2rem;
    }

    h2 {
        font-size: 1.2rem;
    }
`;

type HeaderProps = {
    setPageId: Dispatch<SetStateAction<string | undefined>>;
};

export const Header: React.FC<HeaderProps> = ({ setPageId }) => {
    return (
        <StyledHeader>
            <RotatingImage
                src="./puzzle-white.png"
                alt="Ludopia Logo"
                onClick={() => setPageId("home")}
            />
            <TextHeader>
                <h1>Willkommen bei Ludopia</h1>
                <h2>Die Spiele Community in Salzburg</h2>
            </TextHeader>
        </StyledHeader>
    );
};
