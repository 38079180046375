import { Game } from "./types";

export const browsergames: Game[] = [
    {
        name: "Codenames",
        players: "4+ Spieler",
        details: "Wort- und Deduktionsspiel",
        link: "https://codenames.game",
    },
    {
        name: "Codenames (Kooperativ)",
        players: "2+ Spieler",
        details: "Wort- und Deduktionsspiel",
        link: "https://codenames.game",
    },
    {
        name: "Skribbl.io",
        players: "3-12 Spieler",
        details: "Zeichnen und Begriffe raten",
        link: "https://skribbl.io/",
    },
    {
        name: "Gartic Phone",
        players: "4+ Spieler",
        details: "Kombination aus Zeichnen und Telefon-Stille-Post",
        link: "https://garticphone.com/",
    },
    {
        name: "Geoguessr",
        players: "1+ Spieler",
        details: "Errate den Ort auf der Weltkarte",
        link: "https://www.geoguessr.com/de/party",
    },
    {
        name: "Secret Hitler",
        players: "5+ Spieler",
        details: "Soziales Deduktionsspiel mit Bluffelementen",
        link: "https://secrethitler.io/",
    },
    {
        name: "Stadt Land Fluss",
        players: "2+ Spieler",
        details: "Klassiker: Begriffe zu Kategorien finden",
        link: "https://stadtlandfluss.cool/",
    },
    {
        name: "Dixit",
        players: "3+ Spieler",
        details: "Kreatives Geschichtenerzählen mit Bildern",
        link: "https://play-tixid.online/",
    },
    {
        name: "Cards Against Humanity",
        players: "3+ Spieler",
        details: "Schwarzer Humor und lustige Antworten",
        link: "https://picturecards.online/",
    },
    {
        name: "Krazy Wordz",
        players: "3+ Spieler",
        details: "Kreative Wortspiele mit Fantasiebegriffen",
        link: "https://krazy-wordz.cardboardfrenzy.com/",
    },
    {
        name: "Jetpunk Quizzes",
        players: "1+ Spieler",
        details: "Wissensfragen zu vielen Themen",
        link: "https://www.jetpunk.com/de/all",
    },
    {
        name: "Love Letter",
        players: "2-4 Spieler",
        details: "Kartenspiel mit Überlebensdrang",
        link: "https://netgames.io/games/love-letter/",
    },
];
