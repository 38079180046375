import { ExternalButton, WhatsAppButton } from "../../Button";
import { COLORS } from "../../../constants";
import { TableContainer, Table, Tr, Td } from "../../Table/Table";
import { Dispatch, SetStateAction } from "react";

const StyledTable: React.FC<GruppenProps> = ({ setPageId }) => {
    return (
        <TableContainer>
            <Table>
                <colgroup>
                    <col span={1} />
                    <col span={1} />
                    <col span={1} style={{ minWidth: "200px" }} />
                </colgroup>

                <tbody>
                    <Tr>
                        <Td>🎲 Klassische Gesellschafts- und Brettspiele</Td>
                        <Td>
                            Hier werden klassische Spieleabende (und
                            -Nachmittage) geplant, gemütliche Runden mit
                            gemischten kurzen Spielen aller Art.
                        </Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/J09MJyQ6dwuAyWNfL2GhZX"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>⏰ Ausdauer-Brettspiele</Td>
                        <Td>
                            Manchmal will oder muss man sich für ein Spiel auch
                            mehr Zeit nehmen, weil es einfach mehrere Stunden
                            dauert. Hier ist der Platz für die Planung von
                            Spielen mit aufwendigeren Spielen.
                        </Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/GtubKnh2tOxEvre9ruAsFv"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🛋️ Videospiele vor Ort (Nintendo Switch & Co.)</Td>
                        <Td>
                            Hier werden Freundschaften auf die Probe gestellt.
                            Ob bei Mario Kart, Super Smash Brothers oder Mario
                            Party... es kann nur einen Gewinner geben...
                        </Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/GzWZ5pn3twrDT0YgGnI1fn"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🖥️ Online-Spiele (kostenlos & im Browser)</Td>
                        <Td>
                            Hier trifft man sich gemütlich online, um
                            verschiedenste Browser-Spiele zu spielen.{" "}
                            <a
                                href="#"
                                onClick={() => setPageId("onlinegames")}
                            >
                                Hier geht's zur Liste mit möglichen Spielen...
                            </a>
                        </Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/IkkBEmDdV2G1ryLWAGFLfr"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🎮 Multiplayer online games</Td>
                        <Td>
                            Zocker hier entlang… hier trifft man sich zu
                            Onlinespielen und connectet sich im eigenen{" "}
                            <a href="https://discord.gg/RkNDQmJ23t">
                                Discord-Server
                            </a>
                            . Among us, Lockdown Protocol, ...
                        </Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/D3GGehu4sJI1o47Ac8BW9C"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>♣️ Pa-Pa-Pa-Pokerface</Td>
                        <Td>
                            Was gibt es Besseres, als bei einem gemütlichen
                            Pokerabend herauszufinden, wer das schönste
                            Pokerface hat?
                        </Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/BbuqhtsvuSk21PujVnrwcs"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>💭 Quizzards & Quizzardinen</Td>
                        <Td>
                            Wer weiß denn das? Nur die schlausten Köpfe messen
                            sich hier...
                        </Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/KPcROZeHFtUEMRQ85UwArk"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🎱 Pub games</Td>
                        <Td>
                            In Salzburg gibt es viele Möglichkeiten, wenn man
                            sich in einem Lokal im Billard, Darts, Tischfußball,
                            usw. messen möchte.
                        </Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/KMOkxuioT1G8Qe8jMrJcRL"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🚪 Escape Rooms</Td>
                        <Td>
                            Wo geht’s denn hier raus? Wenn du diese Frage magst,
                            dann erstmal rein hier mit dir - und mal schauen, ob
                            du so einfach wieder raus findest...
                        </Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/DP7eBlbK83XI0mlvufT0si"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🎭 Impro-Theater Workshops</Td>
                        <Td>
                            Keine Ahnung, was hier passiert... Es gibt kein
                            Script und keinen Plan, alles ist improvisiert.
                        </Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/BVzIxFVyO1s43vLTiIgOPL"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🪵 Outdoor-Events</Td>
                        <Td>
                            Schnitzeljagden, Geocaching oder einfach nur eine
                            Runde Wikingerschach gefällig? Hier bist du
                            richtig...
                        </Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/HrB6wrxKEeVAj3p17UWqWe"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                    <Tr>
                        <Td>🏐 Sportspiele aller Art</Td>
                        <Td>
                            Spielen schön und gut, aber bitte auch mit
                            Anstrengung? Dann findest du da die Leute dafür...
                        </Td>
                        <Td>
                            <WhatsAppButton
                                link="https://chat.whatsapp.com/HjDY3RtE6xr5a6fuYMfxtO"
                                label="Gruppe beitreten"
                            />
                        </Td>
                    </Tr>
                </tbody>
            </Table>
        </TableContainer>
    );
};

type GruppenProps = {
    setPageId: Dispatch<SetStateAction<string | undefined>>;
};

export const Gruppen: React.FC<GruppenProps> = (props) => {
    return (
        <>
            <section>
                <h2>Community und Gruppen</h2>
                <WhatsAppButton
                    link="https://chat.whatsapp.com/IYyE1gy3mm079JqjHUQuMf"
                    label="zur WhatsApp Community"
                />
                <ExternalButton
                    link="https://discord.gg/RkNDQmJ23t"
                    label="zum Discord Server"
                    backgroundColor={COLORS.discord}
                />

                <StyledTable {...props} />
            </section>
        </>
    );
};
