import React, { Dispatch, SetStateAction } from "react";
import { Button, ExternalButton, WhatsAppButton } from "../../Button";
import { COLORS } from "../../../constants";

type HomeProps = {
    setPageId: Dispatch<SetStateAction<string | undefined>>;
};

export const Home: React.FC<HomeProps> = ({ setPageId }) => {
    return (
        <section>
            <article>
                <h1>Was ist Ludopia?</h1>
                <Button onClick={() => setPageId("about")} label="Infos" />
                <Button onClick={() => setPageId("faq")} label="FAQ" />
                <Button
                    onClick={() => setPageId("spielregeln")}
                    label="Spielregeln"
                />
            </article>
            <article>
                <h1>Wie komme ich zur Community?</h1>
                <WhatsAppButton
                    link="https://chat.whatsapp.com/IYyE1gy3mm079JqjHUQuMf"
                    label="zur WhatsApp Community"
                />
                <Button
                    onClick={() => setPageId("gruppen")}
                    label="Übersicht WhatsApp Gruppen"
                    backgroundColor={COLORS.whatsapp}
                />
                <ExternalButton
                    link="https://discord.gg/RkNDQmJ23t"
                    label="zum Discord Server"
                    backgroundColor={COLORS.discord}
                />
            </article>
            <article>
                <h1>Wann finden Spiele-Events statt?</h1>
                <Button
                    onClick={() => setPageId("kalender")}
                    label="zum Kalender"
                    backgroundColor="cornflowerblue"
                />
            </article>
        </section>
    );
};
