import { ExternalButton } from "../../Button";
import { COLORS } from "../../../constants";
import { TableContainer, Table, Tr, Td } from "../../Table/Table";
import { Game, browsergames, othergames } from "../../../data";
import { FC } from "react";

type GamesTableProps = {
    games: Game[];
};

const GamesTable: FC<GamesTableProps> = ({ games }) => {
    return (
        <TableContainer>
            <Table>
                <colgroup>
                    <col span={1} />
                    <col span={1} />
                    <col span={1} style={{ minWidth: "200px" }} />
                    <col span={1} />
                </colgroup>
                <tbody>
                    {games.map((game, index) => (
                        <Tr key={index}>
                            <Td>{game.name}</Td>
                            {game.players && <Td>{game.players}</Td>}
                            <Td>{game.details}</Td>
                            <Td>
                                <ExternalButton
                                    backgroundColor={COLORS.main}
                                    link={game.link}
                                    label={game.label ?? "jetzt spielen"}
                                />
                            </Td>
                        </Tr>
                    ))}
                </tbody>
            </Table>
        </TableContainer>
    );
};

export const Onlinegames = () => {
    return (
        <>
            <section>
                <h2>Browsergames</h2>
                <GamesTable games={browsergames} />
            </section>
            <section>
                <h2>Andere Online-Spiele</h2>
                <GamesTable games={othergames} />
            </section>
        </>
    );
};
