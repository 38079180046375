import styled from "styled-components";

const StyledFooter = styled.footer`
    text-align: center;
    padding: 0.5em;
    background: #ad1457;
    color: white;
`;

export const Footer = () => {
    return (
        <StyledFooter>
            <p>&copy; 2024 Bernhard Schmidhuber. Alle Rechte vorbehalten.</p>
        </StyledFooter>
    );
};
