import React, { useState } from "react";
import { Content, Footer, Header, Navigation } from "./components";
import styled from "styled-components";

const AppContainer = styled.div`
    height: 100%;
    display: grid;
    grid-template-rows: auto auto 1fr auto;
`;

function App() {
    const [pageId, setPageId] = useState<string>();

    return (
        <AppContainer>
            <Header setPageId={setPageId} />
            <Navigation setPageId={setPageId} />
            <Content pageId={pageId ?? "home"} setPageId={setPageId} />
            <Footer />
        </AppContainer>
    );
}

export default App;
