import { Game } from "./types";

export const othergames: Game[] = [
    {
        name: "Jackbox Games",
        details: "Verschiedene lustige Partyspiele",
        link: "https://www.jackboxgames.com/",
        label: "zur Website",
    },
    {
        name: "Risiko",
        details: "Taktikspiel um Weltherrschaft",
        link: "https://store.steampowered.com/app/1128810/RISK_Global_Domination/",
        label: "Gratis auf Steam",
    },
    {
        name: "Catan Universe",
        details: "Klassiker: Siedler von Catan",
        link: "https://store.steampowered.com/app/544730/Catan_Universe/",
        label: "Gratis auf Steam",
    },
    {
        name: "Discord Games",
        details: "Minispiele innerhalb der Discord-Plattform",
        link: "https://discord.com/",
        label: "Discord starten",
    },
];
