import { styled } from "styled-components";
import { COLORS } from "../../constants";

export const TableContainer = styled.div`
    overflow-x: auto;
    margin: 20px 0;
`;

export const Table = styled.table`
    width: 100%;
    border-collapse: collapse;

    @media (max-width: 768px) {
        display: block;
        width: 100%;
    }
`;

export const Td = styled.td`
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #ddd;

    &:first-child {
        font-weight: bold;
    }

    @media (max-width: 768px) {
        display: block;
        position: relative;
        padding: 0.5em;
        font-size: 0.9em;
        border: none;
    }
`;

export const Tr = styled.tr`
    background-color: ${COLORS.main}22;

    &:hover {
        background-color: ${COLORS.main}44;
    }

    @media (max-width: 768px) {
        display: block;
        padding: 0.5em;
        margin-bottom: 1em;
        border-radius: 0.5em;
    }
`;
